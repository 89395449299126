



















































import { Field, FieldList } from '@/models/form';
import { ServiceHook, ServiceHookTestModel, ServiceHookTestResultsModel } from '@/models/service-hook-maintenance';
import ServiceHookService from '@/services/service-hook-service';
import {
  Component,
  Inject,
  Prop,
  PropSync,
  Vue
} from 'vue-property-decorator';
import MetadataEditor from '../library-maintenance/metadata-editor.vue';
import ServiceHookTestResult from './service-hook-test-result.vue';
import Card from '@/components/material/Card.vue';

interface KeyValuePair {
  text: string;
  value: string;
}

@Component({
  components: {
    MetadataEditor,
    ServiceHookTestResult,
    Card
  },
})
export default class ServiceHookTest extends Vue {
  @Inject() ServiceHookService!: ServiceHookService

  @Prop() originalServiceHook!: ServiceHook;

  @Prop() serviceHook!: ServiceHook;

  @Prop({default: ""}) serviceHookName!: string;

  @PropSync('show', { default: false }) syncedShow!: boolean;

  JSONDiffPatch = require('jsondiffpatch');

  headerChange(model: KeyValuePair[]){
    const mappedModel = model.map(x => [x.text, x.value]);
    const newFields = Object.fromEntries(mappedModel);
    this.fields.headers.value = newFields;
  }

  model: ServiceHookTestModel = {};

  resultFound = false;

  isRunning = false;

  result: ServiceHookTestResultsModel = {
    ['status-code']: 0,
    headers: {},
    content: '',
    duration: ''
  };

  fieldList = new FieldList({
    headers: new Field(
      {},
      'Correlation Data Headers',
    )
  });

  get fields() { return this.fieldList.fields; }

  get title() {
    return 'Testing Service Hook: ' + this.serviceHookName;
  }

  performTest() {
    this.isRunning = true;
    this.model['additional-headers'] = this.fieldList.fields.headers.value;

    if (this.serviceHook.id === ""){
      this.serviceHook.id = "00000000-0000-0000-0000-000000000000";
    }
    const jsonPatch = this.JSONDiffPatch.diff(this.originalServiceHook, this.serviceHook);
    this.model['json-patch'] = JSON.stringify(jsonPatch);

    const serviceHookId = this.serviceHook.id;
    this.ServiceHookService.testServiceHook(
      serviceHookId,
      this.model
    ).then(result =>  {
      this.result = result;
      this.resultFound = true;
    }).finally( () => {
      this.isRunning = false;
    });
  }

  close(){
    this.syncedShow = false;
  }
}
