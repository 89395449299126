import store, { hasState } from '@/store';
import {
  Module, VuexModule, Mutation,
} from 'vuex-module-decorators';
import config from '@/config';

const shouldLoadState = hasState('local-state');

@Module({
  dynamic: true,
  store,
  name: 'local-state',
  preserveState: shouldLoadState,
})
export default class LocalState extends VuexModule {
  private _defaultPageSize?: number;

  get defaultPageSize() { return this._defaultPageSize ?? config.defaultPageSize; }

  @Mutation
  setDefaultPageSize(size?: number) {
    this._defaultPageSize = size;
  }
}
