


















import { ServiceHookTestResultsModel } from '@/models/service-hook-maintenance';
import {
  Component,
  Prop,
  Vue
} from 'vue-property-decorator';

@Component({})
export default class ServiceHookTestResult extends Vue {
  @Prop() result!: ServiceHookTestResultsModel;

  get renderHeaders(): string {
    return JSON.stringify(this.result.headers);
  }

  HasAdditionalDetails(): boolean {
    const additionalDetails = this.result["additional-details"];
    return additionalDetails != undefined && additionalDetails.length > 0;
  }

  HasHeaders(): boolean {
    const headers = this.result["headers"];
    return Object.keys(headers).length > 0;
  }

  HasContent(): boolean {
    const content = this.result["content"];
    return content != undefined && content !== "";
  }

  IsStatusCodeGood(): boolean {
    const statusCode = this.result["status-code"] || 0;
    if (statusCode >= 200 && statusCode < 300){
      return true;
    }
    return false;
  }
}
