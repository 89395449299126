






















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "@/components/material/Card.vue";

interface KeyValuePair {
  text: string;
  value: string;
}

@Component({
  components: {
    Card,
  },
})
export default class MetadataEditor extends Vue {
  @Prop({ default: () => ({}) }) value: any;

  @Prop({ default: "Metadata" }) label?: string;

  @Prop({ default: "Edit Metadata" }) editTooltip?: string;

  @Prop({ default: "primary lighten-4" }) color?: string;

  @Prop({ default: undefined }) editMode?: boolean;

  @Prop({ default: false }) readonlymetadata?: boolean;

  @Prop({ default: false }) editExisting?: boolean;

  internalEditMode = false;

  get syncedEditMode() {
    return this.editMode === undefined ? this.internalEditMode : this.editMode;
  }

  set syncedEditMode(val: boolean) {
    this.internalEditMode = val;
    this.$emit("update:editMode", val);
  }

  newItem: KeyValuePair = { text: "", value: "" };

  get model(): KeyValuePair[] {
    const keys = Object.keys(this.value);

    return keys.reduce(
      (acc, key) => [
        ...acc,
        Vue.observable({ text: key, value: this.value[key] }),
      ],
      [] as KeyValuePair[]
    );
  }

  set model(pairs: KeyValuePair[]) {
    const newValue = pairs.reduce((acc, pair) => {
      acc[pair.text] = pair.value;
      return acc;
    }, {} as any);
    this.$emit("input", newValue);
  }

  addNewItem() {
    this.model = this.model.concat(this.newItem);

    this.newItem = { text: "", value: "" };
  }

  deleteItem(index: number) {
    const temp = this.model;

    temp.splice(index, 1);

    this.model = temp;
  }

  existingMetadataUpdated()
  {
    if (this.editExisting){
      this.$emit("metadataChanged", this.model );
    }
  }
}
