/* eslint-disable max-classes-per-file */

import { InputValidationRules } from 'vuetify';
import { ApiError } from './hal.d';

function formatString(source: string, arg: any) {
  if (arg === undefined || arg === null) {
    return source;
  }

  return Object.keys(arg).reduce(
    (acc, key) => acc.replace(new RegExp(`{${key}}`, 'gi'), arg[key]),
    source.toString(),
  );
}

export default function formatRules(
  name: string,
  rules: InputValidationRules,
): InputValidationRules {
  return rules.map((rule) => {
    if (typeof rule === 'string') return rule;

    return (value: any) => {
      const result = rule(value);

      if (typeof result === 'string') {
        return formatString(result, { _field_: name });
      }

      return result;
    };
  });
}

export class FieldList {
  constructor(public fields: { [key: string]: Field }) {
  }

  clearAllValues() {
    const keys = Object.keys(this.fields);

    keys.forEach((key) => {
      const field = this.fields[key];

      field.value = undefined;
    });
  }

  clearAllErrors() {
    const keys = Object.keys(this.fields);

    keys.forEach((key) => {
      const field = this.fields[key];

      field.clearErrors();
    });
  }

  addAllErrors(errors: ApiError[]) {
    errors.forEach((error) => this.addError(error));
  }

  addError(error: ApiError) {
    const keys = Object.keys(this.fields);

    const key = keys.find(
      (k) => k === error.property || this.fields[k].mapping === error.property,
    );

    if (!key) return;

    if ('errors' in this.fields[key]) {
      const { errors } = this.fields[key];
      if (errors) {
        errors.push(error.message);
      }
    }
  }
}

export class Field {
  constructor(
    public value: any,
    public label: string,
    public validation?: InputValidationRules,
    public errors?: string[],
    public mapping?: string,
  ) {
    if (typeof validation !== 'undefined') {
      this.validation = formatRules(this.label, validation);
    }
  }

  clearErrors() {
    if (typeof this.errors !== 'undefined') {
      this.errors = [];
    }
  }

  addError(error: string) {
    if (typeof this.errors !== 'undefined') {
      this.errors.push(error);
    }
  }
}

export declare type SelectListItem = {
  value: string;
  text: string;
  header: string;
  disabled: boolean;
  divider: boolean;
}

export type VDataTableOptions = {
  page: number;
  itemsPerPage: number;
  sortBy: string[];
  sortDesc: boolean[];
  groupBy: string[];
  groupDesc: boolean[];
  multiSort: boolean;
  mustSort: boolean;
}

export interface TableOptions {
  create?: boolean;
  link?: boolean;
  delete?: boolean;
  toggleEnable?: boolean;
  selectable?: boolean;
}

export interface SnackbarOptions {
  value: boolean;
  color: string;
  message: string;
  timeout: number;
  closable: boolean;
  showProgress: boolean;
}

export interface EditorOptions {
  validate: (query: string, queryMessage: string) => string | boolean;
  language: string;
  readOnly: boolean;
}
